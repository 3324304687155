// Imports
import ___CSS_LOADER_API_SOURCEMAP_IMPORT___ from "../node_modules/css-loader/dist/runtime/sourceMaps.js";
import ___CSS_LOADER_API_IMPORT___ from "../node_modules/css-loader/dist/runtime/api.js";
var ___CSS_LOADER_EXPORT___ = ___CSS_LOADER_API_IMPORT___(___CSS_LOADER_API_SOURCEMAP_IMPORT___);
// Module
___CSS_LOADER_EXPORT___.push([module.id, `.App {
    display: flex;
    flex-direction: column;
    width: 100vw;
    height: 100vh;
    font-family: Rubik;
}

.fill {
    display: flex;
    flex-direction: column;
    justify-content: center;
    flex: 1 1;
}

.sessionButton {
    padding-left: 13px;
    padding-right: 13px;
    padding-top: 8px;
    padding-bottom: 8px;
    background-color: black;
    border-radius: 10px;
    cursor: pointer;
    color: white;
    font-weight: bold;
    font-size: 17px;
}
`, "",{"version":3,"sources":["webpack://./src/App.css"],"names":[],"mappings":"AAAA;IACI,aAAa;IACb,sBAAsB;IACtB,YAAY;IACZ,aAAa;IACb,kBAAkB;AACtB;;AAEA;IACI,aAAa;IACb,sBAAsB;IACtB,uBAAuB;IACvB,SAAO;AACX;;AAEA;IACI,kBAAkB;IAClB,mBAAmB;IACnB,gBAAgB;IAChB,mBAAmB;IACnB,uBAAuB;IACvB,mBAAmB;IACnB,eAAe;IACf,YAAY;IACZ,iBAAiB;IACjB,eAAe;AACnB","sourcesContent":[".App {\n    display: flex;\n    flex-direction: column;\n    width: 100vw;\n    height: 100vh;\n    font-family: Rubik;\n}\n\n.fill {\n    display: flex;\n    flex-direction: column;\n    justify-content: center;\n    flex: 1;\n}\n\n.sessionButton {\n    padding-left: 13px;\n    padding-right: 13px;\n    padding-top: 8px;\n    padding-bottom: 8px;\n    background-color: black;\n    border-radius: 10px;\n    cursor: pointer;\n    color: white;\n    font-weight: bold;\n    font-size: 17px;\n}\n"],"sourceRoot":""}]);
// Exports
___CSS_LOADER_EXPORT___.locals = {};
export default ___CSS_LOADER_EXPORT___;
